import Vue from 'vue'
import Vuex from 'vuex'
import menu from './modules/menu.js'
import order from './modules/order.js'
import product from './modules/product.js'
import pages from './modules/pages.js'
import media from './modules/media.js'
import reports from './modules/reports.js';
import categ from "@/store/modules/categ";
import dettech from "@/store/modules/dettech";
import alim from "@/store/modules/alimentare";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {
        changeLang(state, payload) {
            localStorage.setItem('currentLanguage', payload.id)
            localStorage.setItem('currentLanguageIcon', payload.icon)
            window.location.reload();
        }
    },
    actions: {
        setLang({ commit }, payload) {
            commit('changeLang', payload)
        }
    },
    modules: {
        menu,
        product,
        order,
        pages,
        media,
        reports,
        alim,
        categ,
        dettech
    }
})