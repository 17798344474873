<template>
  <div class="btn-popup pull-right">
    <b-button v-b-modal.modal-3 :variant="'primary'">Adaugare categorie</b-button>
    <b-modal
      id="modal-1"
      :title="'Modificare categorie accesorii'"
      @ok="onSaveEdit"
      ok-title="Save"
      ok-variant="primary"
    >
      <form class="needs-validation">
        <div class="form">
          <div class="form-group">
            <label for="validationCustom01" class="mb-1">Nume categorie :</label>
            <input
                class="form-control"
                id="validationCustom01"
                type="text"
                v-model="type"
            />
            <input
              class="form-control"
              id="validationCustom01"
              type="text"
              v-model="item.name"
            />
          </div>
          {{item}}
          <div class="form-group form-check">
            <div class="form-check" v-for="(value, key, index) in alim" :key="index">
              <input
                  class="form-check-input"
                  :value="value.id"
                  :id="value.id"
                  type="checkbox"
                  v-model="item.ali"
              />
              <label for="validationCustom01" class="form-check-label mb-1">{{value.nume}}</label>
            </div>

          </div>

          <div class="form-group mb-0">
            <label for="validationCustom02" class="mb-1">
              Imagine:
            </label>
            <input class="form-control" id="validationCustom02" type="file" @change="previewFiles"/>
          </div>
        </div>
      </form>
    </b-modal>
    <b-modal
        id="modal-3"
        :title=title
        @ok="onSave"
        ok-title="Save"
        ok-variant="primary"
    >
      <form class="needs-validation">
        <div class="form">
          <div class="form-group">
            <label for="validationCustom01" class="mb-1">Nume categorie:</label>
            <input
                class="form-control"
                id="validationCustom01"
                type="text"
                v-model="type"
            />
            <input
                class="form-control"
                id="validationCustom01"
                type="text"
                v-model="name"
            />
          </div>
          <div class="form-group form-check">
            <div class="form-check" v-for="(value, key, index) in alim" :key="index">
              <input
                  class="form-check-input"
                  :value="value.id"
                  :id="value.id"
                  type="checkbox"
                  v-model="alimen"
              />
              <label for="validationCustom01" class="form-check-label mb-1">{{value.nume}}</label>
            </div>

          </div>
          <div class="form-group mb-0">
            <label for="validationCustom02" class="mb-1">
              Imagine:
            </label>
            <input class="form-control" id="validationCustom02" type="file" @change="previewFiles"/>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "GlobalCategoryModal",
  props:["categoryType","item","alim", 'title','type'],
  data() {
    return {
      name: "",
      image: "",
      alimen: []
    };
  },
  methods: {
    previewFiles(event){
      console.log(event.target.files);
      this.image = event.target.files[0];
    },
    onSave() {
      //   bvModalEvt.preventDefault();

      let formData = [
        {
          name: this.name,
          image: this.image,
          alim: this.alimen.toString(),
          type: this.type
        }
      ];
      console.log(formData);
      this.$emit("formData", formData);
    },
    onSaveEdit(){
      console.log(this.name);
    }
  }
};
</script>

<style>

</style>