import axios from "axios";
import config from '../../../config.json';

const ax = axios.create({
    baseURL: config.baseUrl,
});
const url = 'action.php';

const state = {
    detTechList: []
}
const getters = {
    getList(state) {
        return state.detTechList;
    }
}
const actions = {
    getList(context) {
        let formData = new FormData();
        formData.append('post_case', 'getDetTech');
        axios({
            method: 'post',
            url: '../action.php',
            data: formData,
            config: {headers: {'Content-Type': 'multipart/form-data'}}
        })
        .then(function (response) {
            state.detTechList = response.data;
            context.commit('getList', response);
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
    },
    saveEdit: (context, item) => {
        let formData = new FormData();
        formData.append('id', item.id);
        formData.append('name', item.nume);
        formData.append('um', item.um);
        formData.append('post_case', 'editDetTeh');
        axios({
            method: 'post',
            url: '../action.php',
            data: formData,
            config: {headers: {'Content-Type': 'multipart/form-data'}}
        })
            .then(function (response) {
                console.log(response);
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    },

    saveAdd: (context, item) => {
        let formData = new FormData();
        console.log(item);
        formData.append('name', item.nume);
        formData.append('um', item.um);
        formData.append('post_case', 'addDetTech');
        axios({
            method: 'post',
            url: '../action.php',
            data: formData,
            config: {headers: {'Content-Type': 'multipart/form-data'}}
        })
            .then(function (response) {
                console.log(response);
                location.reload();
            })
            .catch(function (response) {
                console.log(response);
            });
    },
    /*assignOrder(context, assign) {
        return new Promise((resolve, reject) => {
            axios.post('/assignOrder', assign)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response.data.errors)
                })
        })
    }*/

    deleteDet: (context, item) => {
        return new Promise(function (resolve, reject) {
            let formData = new FormData();
            formData.append('id', item.id);
            formData.append('post_case', 'delDetTech');
            axios({
                method: 'post',
                url: '../action.php',
                data: formData,
                config: {headers: {'Content-Type': 'multipart/form-data'}}
            })
            .then(function (response) {
                resolve(response);
                console.log(response);
            })
            .catch(function (response) {
                console.log(response);
            })
        });


    }

}
const mutations = {
    getList(state, payload) {
        state.detTechList = payload.data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}