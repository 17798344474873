import axios from "axios";
import config from '../../../config.json';

const ax = axios.create({
    baseURL: config.baseUrl,
});

const state = {
    testList: [],
    accesList: []
}
const getters = {
    getCateg(state) {
        return state.testList;
    },
    getCategAcces(state) {
        return state.accesList;
    }
}
const actions = {
    getCateg(context) {
        let formData = new FormData();
        formData.append('post_case', 'categ');
        axios({
            method: 'post',
            url: '../action.php',
            data: formData,
            config: {headers: {'Content-Type': 'multipart/form-data'}}
        })
        .then(function (response) {
            console.log(response);
            context.commit('getCateg', response);
        })
        .catch(function (response) {
            console.log(response);
        })
    },
    getCategAcces(context) {
        let formData = new FormData();
        formData.append('post_case', 'categAcces');
        axios({
            method: 'post',
            url: '../action.php',
            data: formData,
            config: {headers: {'Content-Type': 'multipart/form-data'}}
        })
            .then(function (response) {
                console.log(response);
                context.commit('getCategAcces', response);
            })
            .catch(function (response) {
                console.log(response);
            })
    },

    saveEdit: (context, item) => {
        let formData = new FormData();
        formData.append('name', item.name);
        formData.append('id', item.id);
        formData.append('post_case', 'editCat');
        axios({
            method: 'post',
            url: '../action.php',
            data: formData,
            config: {headers: {'Content-Type': 'multipart/form-data'}}
        })
            .then(function (response) {
                console.log(response);
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    },
    saveAdd: (context, item) => {
        let formData = new FormData();
        formData.append('name', item[0].name);
        formData.append('image', item[0].image);
        formData.append('alim', item[0].alim);
        formData.append('type', item[0].type);
        formData.append('post_case', 'addCat');
        axios({
            method: 'post',
            url: '../action.php',
            data: formData,
            config: {headers: {'Content-Type': 'multipart/form-data'}}
        })
        .then(function (response) {
            console.log(response);
            location.reload();
        })
        .catch(function (response) {
            console.log(response);
        });
    },
    deleteCat: (context, item) => {
        let formData = new FormData();
        formData.append('id', item.id);
        formData.append('post_case', 'delCat');
        axios({
            method: 'post',
            url: '../action.php',
            data: formData,
            config: {headers: {'Content-Type': 'multipart/form-data'}}
        })
        .then(function (response) {
            console.log(response);
            location.reload();
        })
        .catch(function (response) {
            console.log(response);
        });
    }

}
const mutations = {
    getCateg(state, payload) {
        state.testList = payload.data;
    },
    getCategAcces(state, payload) {
        state.accesList = payload.data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}