import axios from "axios";
import config from '../../../config.json';

const ax = axios.create({
    baseURL: config.baseUrl,
});
const url = 'action.php?a=alim';

const state = {
    alimList: []
}
const getters = {
    getAlim(state) {
        console.log(state);
        return state.alimList;
    }
}
const actions = {
    getAlim(context) {
        ax.get(url)
            .then(response => {
                console.log(response.data);
                context.commit('getAlim', response);
            })
            .catch(error => {
                console.log('error', error);
            })
    }

}
const mutations = {
    getAlim(state, payload) {
        state.alimList = payload.data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}