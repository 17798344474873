<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="Digital" title="Category" />
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Categorii accesorii</h5>
            </div>
            <div class="card-body">
              <GlobalCategoryModal
                  @formData="formData"
                  categoryType="digital"
                  :item="category"
                  :alim="alim"
                  :title="'Adaugare categorii pentru accesori'"
                  :type="'acces'"
              />
              <div class="table-responsive datatable-vue product-physical">
                <b-modal id="modal-2" title="Confirmation" @ok="deleteRow(uuid)">
                  <p class="my-4">Are you sure!</p>
                </b-modal>
                <b-table
                    :select-mode="selectMode"
                    class="text-center"
                    striped
                    hover
                    head-variant="light"
                    bordered
                    show-empty
                    stacked="md"
                    :items="items"
                    :fields="tablefields"
                    :filter="filter"
                    :current-page="currentPage"
                    :per-page="perPage"
                    @filtered="onFiltered"
                    :selectable="true"
                    @row-selected="rowSelected"
                >

                  <template #cell(image)="field">
                    <img
                        height="150px"
                        :src="field.item.img"
                    />
                  </template>
                  <template v-slot:cell()="{ value, item, field: { key } }">
                    <template v-if="edit != item.id">{{ value }}</template>
                    <b-form-input type="text" v-else v-model="item[key]" />
                  </template>

                  <template v-slot:cell(actions)="{ item: { id, name } }">
                    <span
                        style="font-size: 20px; color: blue;"
                        v-if="edit != id && id !== null"
                        v-b-modal.modal-1
                        @click="onEdit(id, name)"
                    >
                      <feather
                          type="edit-2"
                          stroke="#3758FD"
                          stroke-width="1"
                          size="18px"
                          fill="#3758FD"
                          stroke-linejoin="round"
                      ></feather>
                    </span>
                    <span
                        v-if="edit != id"
                        v-b-modal.modal-2
                        @click="getIndex(id)"
                        style="font-size: 20px; color: #FA399F;"
                    >
                      <feather
                          type="trash"
                          stroke="#F72E9F"
                          size="18px"
                          fill="#F72E9F"
                      ></feather>
                    </span>
                    <feather
                        v-if="edit === id && id !== ''"
                        size="18px"
                        stroke="#39CCC6"
                        stroke-width="5"
                        type="check"
                        @click="onSave(id, name)"
                    ></feather>
                  </template>

                </b-table>
              </div>
              <b-col md="12" class="my-1 pagination-justify p-0">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    aria-controls="my-table"
                    class="mt-4"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import statusIcon from "../../components/featherIcons/status-icon.vue";

export default {
  components: { statusIcon },
  data() {
    return {
      tablefields: [
        { key: "image", label: "Imagine", sortable: false },
        { key: "name", label: "Categorie", sortable: true },
        { key: "ali", label: "Alimentare", sortable: true },
        { key: "actions" }
      ],
      selectMode: "single",
      filter: null,
      totalRows: 1,
      totalRows1: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      add: false,
      save: false,
      edit: null,
      item: "",
      uuid : "",
      index: null,
      categories: [],
      categoriess: [],
      category:''
    };
  },
  created() {
    this.$store.dispatch("categ/getCategAcces");
    this.$store.dispatch("alim/getAlim");
  },
  computed: {
    ...mapGetters({
      items: "categ/getCategAcces",
      alim: "alim/getAlim"
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key };
          });
    },
    selectedRows() {
      return this.items.filter(item => item.delete);
    }
  },

  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.totalRows1 = this.alim.length;

  },
  methods: {
    /* addAlim() {


     },*/
    getIndex(id) {
      this.uuid = id;
    },
    formData(e) {
      console.log("modal data", e);
      this.$store.dispatch("categ/saveAdd", e);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onEdit(id, name) {
      /*if (id !== "undefined") {
        this.edit = this.edit !== id ? id : null;
      }*/
      this.category = this.items.find(c => c.id === id);
    },
    onSave(id, name) {
      this.save = false;
      this.add = false;
      this.edit = 1000;
      this.$store.dispatch("categ/saveEdit", {id: id, name: name});
    },
    doCancel(id) {
      this.edit = null;
      this.item = null;
    },
    deleteRow(id) {
      //this.items.splice(this.items.findIndex(e => e.id === id),1);
      this.$store.dispatch("categ/deleteCat", {id: id});
    },
    rowSelected(item) {
      if (item[0]) {
        this.edit = this.edit !== item[0].id ? item[0].id : null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
table.text-center * {
  text-align: center;
}
</style>