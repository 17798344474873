<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="Digital" title="Add Products"/>
      </div>
      <div class="row product-adding">
        <!-- start -->
        <div class="col-xl-4">
          <div class="card">
            <div class="card-header">
              <h5>Generalitati</h5>
            </div>
            <div class="card-body">
              <div class="digital-add needs-validation">
                <div class="form-group">
                    <input
                        class="form-control"
                        type="hidden"
                        disabled
                        v-model="form.uuid"
                        required=""/>
                </div>
                <div class="form-group">
                  <label
                      for="validationCustom01"
                      class="col-form-label pt-0"
                  ><span>*</span> Denumire produs</label>
                  <ValidationProvider
                      name="title"
                      rules="required"
                      v-slot="{ errors }">
                    <input
                        class="form-control"
                        id="validationCustom01"
                        type="text"
                        v-model="form.title"
                        required="true"/>
                    <span
                        class="block text-danger text-xs absolute bottom-0 left-0">
                          {{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label
                      for="validationCustomtitle"
                      class="col-form-label pt-0">
                    <span>*</span> SKU</label>
                  <ValidationProvider
                      name="sku"
                      rules="required"
                      v-slot="{ errors }">
                    <input
                        class="form-control"
                        id="validationCustomtitle"
                        v-model="form.sku"
                        type="text"
                        required=""/>
                    <span
                        class="block text-danger text-xs absolute bottom-0 left-0">
                          {{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label class="col-form-label"
                  ><span>*</span> Categorie</label>
                  <select class="custom-select" v-model="form.categorie" required="">
                    <option v-for="(item, idx) in categ" :key="idx" :value="item.id">{{ item.name }}</option>

                  </select>
                </div>
                <label class="col-form-label">Tip alimentare</label>
                <div class="form-group"
                     style="display: flex; flex-direction: row; justify-content: flex-start; height: 35px;">

                  <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated selector">
                    <label class="d-block" for="electric">
                      <input
                          class="checkbox_animated"
                          id="electric"
                          type="radio"
                          value="Electric"
                          v-model="form.power"
                          checked
                      />
                      Electric
                    </label>
                  </div>

                  <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated selector">
                    <label class="d-block" for="benzina">
                      <input
                          class="checkbox_animated"
                          id="benzina"
                          type="radio"
                          value="Benzina"
                          v-model="form.power"
                      />
                      Benzina
                    </label>
                  </div>

                  <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated selector">
                    <label class="d-block" for="baterie">
                      <input
                          class="checkbox_animated"
                          id="baterie"
                          type="radio"
                          value="Baterie"
                          v-model="form.power"
                      />
                      Baterie
                    </label>
                  </div>


                </div>

                <div class="form-group">
                  <label class="col-form-label">Descriere scurta</label>
                  <textarea rows="5" cols="12" v-model="form.sortSum"></textarea>
                </div>
                <div class="form-group">
                  <label for="validationCustom02" class="col-form-label">
                    <span>*</span>Pret produs</label>
                  <input
                      class="form-control"
                      id="validationCustom02"
                      type="text"
                      required=""
                      v-model="form.price"
                  />
                </div>
                <div class="form-group">
                  <label for="validationStock" class="col-form-label">
                    <span>*</span>Cantitate</label>
                  <input
                      class="form-control"
                      id="validationStock"
                      type="text"
                      required=""
                      v-model="form.stock"
                  />
                </div>
                <div class="form-group"
                     style="display: flex; flex-direction: row; justify-content: flex-start; height: 35px;">
                  <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated selector">
                    <label class="d-block" for="new">
                      <input
                          class="checkbox_animated"
                          id="new"
                          type="checkbox"
                          name="new"
                          v-model="form.new"
                      />
                      New
                    </label>
                  </div>

                  <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated selector">
                    <label class="d-block" for="sale">
                      <input
                          class="checkbox_animated"
                          id="sale"
                          type="checkbox"
                          name="sale"
                          v-model="form.sale"
                      />
                      Sale
                    </label>
                  </div>

                  <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated selector">
                    <label class="d-block" for="discount" v-if="form.sale">
                      Discount:
                      <input
                          class="radio_animated"
                          id="discount"
                          type="text"
                          name="discount"
                          v-model="form.discount"
                      />
                      %
                    </label>
                  </div>


                </div>
                <div class="card-header">
                  <h5>Detalii tehnice</h5>
                </div>
                <select class="custom-select" v-model="dettec" required="" @change="addLine(dettec)">
                  <option v-for="(item, idx) in detTech" :key="idx" :value="item.id">{{ item.nume }}</option>

                </select>
                <div class="form-group" v-for="(item,index) in form.det" :key="index"
                     style="display: flex; flex-direction: row;">
                  <label for="validationCustom02" class="col-form-label" style="width: 20%;">
                    {{ item.nume }}</label>
                  <input
                      class="form-control"
                      v-model="item.val"
                      type="text"
                      required=""
                      style="width: 70%; margin: 15px;"
                  />
                  <label for="validationCustom02" class="col-form-label" style="width: 20%;">
                    {{ item.um }}
                  </label>
                  <button type="button" class="btn btn-light ml-1" @click="removeLine(item.id)">
                    X
                  </button>
                </div>
                <div class="card">
                  <div class="card-header">
                    <h5>Accesorii</h5>
                  </div>{{accList}}
                  <select class="custom-select" v-model="accessories" required="" @change="addAc(accessories)">

                    <option v-for="(item, idx) in accList" :key="idx" :value="item.id">{{ item.id }}-{{ item.title }}-{{accessories}}</option>

                  </select>
                  <div class="form-group" v-for="(item,index) in form.acc" :key="index"
                       style="display: flex; flex-direction: row;">
                    <label for="validationCustom02" class="col-form-label" style="width: 70%;">
                      <b>{{ item.title }}</b><br><i>Pret lista: {{item.price}} RON</i></label>
                    <label for="validationCustom02" class="col-form-label" style="width: 20%;">
                      Pret special
                    </label>
                    <input
                        class="form-control"
                        v-model="item.pret"
                        type="text"
                        required=""
                        style="width: 50%; margin: 15px;"
                    />
                    <label for="validationCustom02" class="col-form-label" style="width: 10%;">
                      RON
                    </label>
                    <button type="button" class="btn btn-light ml-1" @click="removeAcc(item.id)">
                      X
                    </button>
                  </div>

                </div>
                <div class="card-header">
                  <label class="col-form-label pt-0">Adaugare imagine</label>
                  <vue-dropzone
                      id="singledropzone"
                      :options="singledropzoneOptions"
                      class="dropzone digits"
                      v-on:vdropzone-sending="sendingEvent"
                      v-on:vdropzone-removed-file="removedFile">
                  </vue-dropzone>
                </div>


              </div>
              <!-- form end working -->
            </div>
          </div>
        </div>
        <div class="col-xl-8">
          <div class="card">
            <div class="card-header">
              <h5>Dotare și funcție</h5>
            </div>
            <div class="card-body">
              <div class="digital-add needs-validation">
                <div class="col-12" style="display: flex; flex-direction: row; align-items: center;"
                     v-for="(item,idx) in form.dotari" :key="idx">
                  <div class="form-group col-5">
                    <label for="validationCustom05" class="col-form-label pt-0">
                      Titlu dotare</label>
                    <input
                        class="form-control"
                        id="validationCustom05"
                        type="text"
                        required=""
                        v-model="item.titlu"
                    />
                  </div>
                  <div class="form-group col-5">
                    <label class="col-form-label">Descriere dotare</label>
                    <textarea rows="4" cols="12" v-model="item.descriere"></textarea>
                  </div>
                  <div class="col-2">
                    <button type="button" class="btn btn-success" @click="addDotari()">
                      <i class="fa fa-plus"></i>
                    </button>
                    <button type="button" class="btn btn-warning" @click="removeDotari(idx)">
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <h5>Descriere lunga</h5>
            </div>
            <div class="card-body">
              <div class="digital-add needs-validation">
                <div class="md-12">
                  <vue-editor v-model="form.desc"></vue-editor>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <h5>Meta Data</h5>
            </div>
            <div class="card-body">
              <div class="digital-add needs-validation">
                <div class="form-group">
                  <label for="validationCustom05" class="col-form-label pt-0">
                    Meta Title</label>
                  <input
                      class="form-control"
                      id="validationCustom05"
                      type="text"
                      required=""
                      v-model="form.metat"
                  />
                </div>
                <div class="form-group">
                  <label class="col-form-label">Meta Description</label>
                  <textarea rows="4" cols="12" v-model="form.metad"></textarea>
                </div>
                <div class="form-group mb-0">
                  <div class="product-buttons text-center">
                    <button type="button" class="btn btn-primary" @click="saveProd">
                      Adaugare
                    </button>
                    <button type="button" class="btn btn-light ml-1">
                      Renuntare
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
// Import the editor
//import CKEditor from "@ckeditor/ckeditor5-vue";
import {mapGetters} from "vuex";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {uuid} from 'vue-uuid';
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider
    //ckeditor: CKEditor.component
  },
  data() {
    return {
      form: {
        uuid: '',
        title: "",
        sku: '',
        categorie: '',
        sortSum: '',
        price: '',
        sale: '',
        discount: '',
        stock: '',
        new: '',
        desc: '',
        metad: '',
        metat: '',
        det: [],
        acc:[],
        dotari: [{
          titlu: '',
          descriere: ''
        }],
        type: 'prod',
        power: ''
      },
      editor: ClassicEditor,
      editorData: "<p>Dummy data fro ck Edit</p>",
      singledropzoneOptions: {
        url: "../action.php",
        thumbnailWidth: 150,
        maxFiles: 1,
        maxFilesize: 2,
        addRemoveLinks: true,
        destroyDropzone: false,
        dictDefaultMessage:
            "<i class='icon-cloud-up'></i><h6>Drop files here or click to upload.</h6>"
      },
      content: "<h1>Some initial content</h1>",
      dettec: '',
      accessories: '',
      accList: []

    };
  },
  created() {
    this.$store.dispatch("categ/getCateg");
    this.$store.dispatch("dettech/getList");
    this.$store.dispatch("product/getAccessoriesList");
    this.getUuid();
  },
  computed: {
    ...mapGetters({
      categ: "categ/getCateg",
      detTech: "dettech/getList",
      access: "product/getAccessoriesList"
    }),
    power(){
      return this.form.power;
    }
  },
  watch: {
    power(){
      this.getAccList();
    }
  },
  methods: {
    getAccList(){
      this.accList = this.access.filter(a=> a.power.toLowerCase()===this.form.power.toLowerCase());
    },
    getUuid() {
      this.form.uuid = uuid.v1();
    },
    saveProd() {
      console.log(this.form);
      /*this.$store.dispatch("product/saveProduct", this.form).then((res) => {
        //this.$store.dispatch('dettech/getList', this.active)
      })
      .catch((error) => {
        alert(error);
      });*/
    },
    addLine(idx) {
      const element = this.detTech.find(d => d.id === idx);
      const id = this.detTech.indexOf(element);
      if (idx) {
        this.detTech.splice(id, 1);
        this.form.det.push({
          id: element.id,
          nume: element.nume,
          um: element.um,
          val: element.val
        });
      }
    },
    removeLine(idx) {
      const element = this.form.det.find(d => d.id === idx);
      const id = this.form.det.indexOf(element);
      this.detTech.sort((a, b) => a.nume - b.nume);
      if (idx) {
        this.form.det.splice(id, 1);
        this.detTech.push({
          id: element.id,
          nume: element.nume,
          um: element.um
        });
      }
    },

    addAc(idx){
      console.log(idx)
      console.log(this.accList)
      const el = this.accList.find(d => d.id === idx);
      const id = this.accList.indexOf(el);
      if(idx){
        this.accList.splice(id, 1);
        this.form.acc.push({
          ...el,
          pret: el.pret
        });
      }
    },

    removeAcc(idx){
      const element = this.form.acc.find(d => d.id === idx);
      const id = this.form.acc.indexOf(element);
      if (idx) {
        this.form.acc.splice(id, 1);
        this.accList.push({
          ...element
        });
      }
      this.accList = this.access.filter(a=> a.power.toLowerCase()===this.form.power.toLowerCase());
    },
    addDotari() {
      this.form.dotari.push({
        titlu: '',
        descriere: ''
      })
    },
    removeDotari(idx) {
      if (this.form.dotari.length > 1)
        this.form.dotari.splice(idx, 1);
    },
    sendingEvent(file, xhr, formData) {
      formData.append('post_case', 'cat');
      formData.append('uuid', this.form.uuid);
    },
    removedFile(file, error, xhr, formData) {
      this.$store.dispatch("product/removeImage", this.form.uuid);
    }
  }
};
</script>
<style scoped>
.ck-content {
  height: 500px;
}

.selector {
  margin-right: 10%;
}

.dz-details {
  background-color: transparent;
  color: #ffffff;
}

</style>