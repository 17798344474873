import Vue from 'vue'
import VueRouter from 'vue-router'
import Body from '../components/Body.vue';
import dashboard from '../pages/dashboard.vue';
import CategoryDigital from '../pages/produs/Category.vue';
import SubCategoryDigital from '../pages/produs/SubCategory.vue';
import ProductListDigital from '../pages/produs/ProductList.vue';
import AddProductDigital from '../pages/produs/AddProduct.vue';
import DetaliiTechnice from '../pages/produs/detTech.vue';
import AccessoriesCategory from '../pages/accessories/accessoriesCategory.vue';
import accessoriesList from '../pages/accessories/accessoriesList.vue';
import addAccessories from '../pages/accessories/addAccessories.vue';


import UserList from '../pages/users/user-list.vue';
import CreateUser from '../pages/users/create-user.vue';

import Auth from '../pages/authentication/index.vue';

Vue.use(VueRouter)

const routes = [
    { path: '', redirect: { name: 'default' } },
    {
        path: '/dashboard',
        component: Body,
        children: [{
            path: 'default',
            name: 'default',
            component: dashboard,
            meta: {
                title: 'Stihl - Admin page',
            }
        }]
    },
    {
        path: '/produs',
        component: Body,
        children: [{
                path: 'category',
                name: 'Categorii produse',
                component: CategoryDigital,
                meta: {
                    title: 'Categorii produse | Stihl',
                }
            },
            {
                path: 'product-list',
                name: 'product-list',
                component: ProductListDigital,
                meta: {
                    title: 'Lista de produse | Stihl',
                }
            },
            {
                path: 'add-product',
                name: 'add-product',
                component: AddProductDigital,
                meta: {
                    title: 'Add product | Stihl',
                }
            },
            {
                path: 'detalii-tecnice',
                name: 'det-tech',
                component: DetaliiTechnice,
                meta: {
                    title: 'Add product | Stihl',
                }
            },
        ]
    },
    {
        path: '/accessories',
        name: 'Accessories',
        component: Body,
        children: [
            {
                path: 'accessoriesCategory',
                name: 'Categorii accesorii',
                component: AccessoriesCategory,
                meta: {
                    title: 'Categorii accesorii | Stihl',
                }
            },
            {
                path: 'accessoriesList',
                name: 'Lista accesorii',
                component: accessoriesList,
                meta: {
                    title: 'Lista accesorii | Stihl',
                }
            },
            {
                path: 'addAccessories',
                name: 'Adaugare accesorii',
                component: addAccessories,
                meta: {
                    title: 'Adaugare accesorii | Stihl',
                }
            }
        ],
        meta: {
            title: 'Accesorii | Stihl',
        }
    },
    {
        path: '/users',
        name: 'users',
        component: Body,
        children: [{
                path: 'user-list',
                name: 'user-list',
                component: UserList,
                meta: {
                    title: 'User | Stihl',

                }
            },
            {
                path: 'create-user',
                name: 'create-user',
                component: CreateUser,
                meta: {
                    title: 'User | Stihl',

                }
            }
        ]
    },
    {
        path: '/auth/login',
        name: 'login',
        component: Auth,
        meta: {
            title: 'Login | Multikart - Premium Admin Template',

        }
    }
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
     
    const  path = ['/auth/login','/auth/register'];
    if (path.includes(to.path) || localStorage.getItem('user')  ){
      return next();
    }
    next('/auth/login');
  });

export default router