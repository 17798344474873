import axios from "axios";

import config from '../../../config.json'
const ax = axios.create({
    baseURL: config.baseUrl,
});
const url = 'action.php';

const state = {
    products: [],
    accessories: [],
    subCategoryProduct: [],
    productList: [],
    currency: {
        curr: 'usd',
        symbol: '$'
    },
    loadingStatus: false
}
const getters = {
    getProducts: (state) => {
        return state.products;
    },
    getAccessories: (state) => {
        return state.accessories;
    },
    getAccessoriesList: (state) => {
        return state.productList;
    },
    getProductById: (state) => {
        return id => state.products.find((product) => {
            return product.sku === +id
        })
    },
    loadingStatus: (state) => {
        return state.loadingStatus;
    },
    changeCurrency: (state) => {
        if (state.currency.curr === 'eur') {
            state.currency.curr = 0.90
            return state.currency
        } else if (state.currency.curr === 'inr') {
            state.currency.curr = 70.93
            return state.currency
        } else if (state.currency.curr === 'gbp') {
            state.currency.curr = 0.78
            return state.currency
        } else if (state.currency.curr === 'usd') {
            state.currency.curr = 1
            return state.currency
        }
    },
}
const actions = {
    getProducts: (context) => {
        let formData = new FormData();
        formData.append('post_case', 'getProducts');
        axios({
            method: 'post',
            url: '../action.php',
            data: formData,
            config: {headers: {'Content-Type': 'multipart/form-data'}}
        })
        .then(function (response) {
            //state.detTechList = response.data
            console.log(response.data);
            context.commit('getProducts', response);
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
    },
    getAccessories:(context) => {
        let formData = new FormData();
        formData.append('post_case', 'getAccessories');
        axios({
            method: 'post',
            url: '../action.php',
            data: formData,
            config: {headers: {'Content-Type': 'multipart/form-data'}}
        })
            .then(function (response) {
                console.log(response);
                //state.accessories = response.data;
                context.commit('getAccessories', response);
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    },
    saveProduct: (context, item) => {
        let det = '';
        item.det.forEach(d => {
            det += d.id+'->'+d.val+'|';
        })
        let formData = new FormData();
        formData.append('post_case', 'addProdus');
        formData.append('categorie', item.categorie);
        formData.append('desc', item.desc);
        formData.append('det', det);
        formData.append('discount', item.discount);
        formData.append('dotari', JSON.stringify(item.dotari));
        formData.append('accessories', JSON.stringify(item.acc));
        formData.append('metad', item.metad);
        formData.append('metat', item.metat);
        formData.append('new', item.new);
        formData.append('price', item.price);
        formData.append('sale', item.sale);
        formData.append('sku', item.sku);
        formData.append('sortSum', item.sortSum);
        formData.append('stoc', item.stock);
        formData.append('title', item.title);
        formData.append('uuid', item.uuid);
        formData.append('type', item.type);
        formData.append('power', item.power);
        console.log(item.dotari);
        axios({
            method: 'post',
            url: '../action.php',
            data: formData,
            config: {headers: {'Content-Type': 'multipart/form-data'}}
        })
            .then(function (response) {
                console.log(response);
                /*state.detTechList = response.data;
                context.commit('getProducts', response);*/
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });

    },
    removeImage:(context, item) => {
        let formData = new FormData();
        formData.append('uuid', item);
        formData.append('post_case', 'catre');
        axios({
            method: 'post',
            url: '../action.php',
            data: formData,
            config: {headers: {'Content-Type': 'multipart/form-data'}}
        })
        .then(function (response) {
            console.log(response);
            /*state.detTechList = response.data;
            context.commit('getProducts', response);*/
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
    },
    deleteProduct:(context, item) => {
        let formData = new FormData();
        formData.append('uuid', item);
        formData.append('post_case', 'delProd');
        axios({
            method: 'post',
            url: '../action.php',
            data: formData,
            config: {headers: {'Content-Type': 'multipart/form-data'}}
        })
        .then(function (response) {
            console.log(response);
            /*state.detTechList = response.data;
            context.commit('getProducts', response);*/
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
    },

    getAccessoriesList: (context, item) => {
        let formData = new FormData();
        formData.append('power', item);
        formData.append('post_case', 'getAccessByType');
        axios({
            method: 'post',
            url: '../action.php',
            data: formData,
            config: {headers: {'Content-Type': 'multipart/form-data'}}
        })
        .then(function (response) {
            console.log(response);
            context.commit('getAccessoriesList', response);

        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
    },
    changeCurrency: (context, payload) => {
        context.commit('changeCurrency', payload)
    },
}
const mutations = {
    loadingStatus: (state, payload) => {
        state.loadingStatus = payload;
    },
    getProducts: (state, payload) => {
        state.products = payload.data;
    },
    getAccessories: (state, payload) => {
        state.accessories = payload.data;
    },
    getAccessoriesList: (state, payload) => {
        state.productList = payload.data;
    },
    changeCurrency: (state, payload) => {
        state.currency = payload
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}